import React, { useState } from 'react';
import { Link, graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Question from '../components/Question';
import JSONData from "../data/quiz/historie.json";

const HistorieQuiz = props => {
  const { data } = props.data;

  const title = "Historie-quiz";

  return (
      <Layout showRelated={true}>
        <SEO
            description="God på historie? La oss teste din kunnskap med denne kule quizen!"
            title={title} />
        <div className="quiz">
          {<div className="justify-content-start pb-2">
            <div className="pt-6">
              <div dangerouslySetInnerHTML={{ __html: data.html }} />
            </div>
          </div>}
          <div>
            {JSONData && JSONData.map((it, index) => {
              return (
                <Question addBtn={true} index={index+1} data={it}/> 
              )})}
          </div>
        </div>
      </Layout>
  );
};

export const query = graphql`
  query {
    data: markdownRemark(fileAbsolutePath: {regex: "/(Historie.md)/"}) {
      html
    }
  }
`;

export default HistorieQuiz;
